<template>
  <v-container fluid pa-3>
    <v-row class="center">
      <img src="/img/not-found.jpg" width="100%" alt="">

      <h2 class="display-1 mt-5 mb-3 col-12 not-found">{{$t('public.errorMsg')}}</h2>
      <div>{{$t('public.pageNotFound')}}</div>

    </v-row>
  </v-container>
</template>

<script>

export default {
  
};
</script>
<style lang="scss" scoped>
  .not-found {
    text-align: center;
    margin: auto;
    color: var(--brandPrimColor);
  }
</style>

